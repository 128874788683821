import ajax from "./ajax"

// 预订信息
export function listActivityByCompany(params){
    return ajax({
        url:'/api/booking/listActivityByCompany',
        method:'get',
        params
    })
}

//根据id获取预约详情信息
// export function bookingInfoById(id){
//     return ajax({
//         url:`/api/booking/bookingInfoById/${id}`,
//         method:'get'
//     })
// }
export function bookingInfoById(params){
    return ajax({
        url:'/api/booking/bookingInfoById',
        method:'get',
        params
    })
}

//根据预订代码查询附件列表
export function listAnnexByCode(params){
    return ajax({
        url:'/api/booking/listAnnexByCode',
        method:'get',
        params
    })
}

//获取信息列表
export function listRecordByCode(params){
    return ajax({
        url:'/api/booking/listRecordByCode',
        method:'get',
        params
    })
}

//获取企业消息列表
export function listMessage(params){
    return ajax({
        url:'/api/message/listMessage',
        method:'get',
        params
    })
}

//修改手机号
export function changePhone(params){
    return ajax({
        url:'/api/company/changePhone',
        method:'put',
        params
    })
}

//修改密码
export function changPassword(params){
    return ajax({
        url:'/api/company/changPassword',
        method:'put',
        params
    })
}

//取消/撤回
export function canceled(params){
    return ajax({
        url:'/api/booking/canceled',
        method:'put',
        params
    })
}

//获取绑定微信用户
export function getWXUsers(params){
    return ajax({
        url:'/api/member/listMemberByCompany',
        method:'get',
        params
    })
}

//解绑
export function untieMember(params){
    return ajax({
        url:'/api/member/untieMember',
        method:'put',
        params
    })
}

//消息
export function getNumberByCompany(params){
    return ajax({
        url:'/api/message/getNumberByCompany',
        method:'get',
        params
    })
}


//评价
export function saveSuggest(data){
    return ajax({
        url:'/api/evaluation/saveSuggest',
        method:'post',
        data
    })
}


//获取企业信息
export function getCompanyInfo(params){
    return ajax({
        url:'/api/company/getCompanyInfo',
        method:'get',
        params
    })
}

//删除合同
export function removeConfirmation(params){
    return ajax({
        url:'/api/booking/removeConfirmation',
        method:'put',
        params
    })
}