<template>
    <div class="content">
        <router-view v-if="$route.meta.personal" />
    </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.content{
    width:960px;
    margin-left:20px;
    background-color:#F8F8F8;
}
</style>