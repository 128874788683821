import axios from 'axios'
import store from '../store/index'

// 相单于实例化一个axios对象
const service = axios.create({
    //正式
    baseURL: "http://venue.bailingkeji.com",
    // baseURL:"http://8.142.81.230:9002",
    // baseURL:"https://ydzx.bloomage.com/api/",
    timeout: 100000,
    changOrigin: true, //允许跨域
})


// 请求拦截器
service.interceptors.request.use(
    config => {
        // if(ocalStorage.getItem('Authorization ') ){

        config.headers.Authorization = localStorage.getItem('Authorization')
        config.headers.userId = store.state.id
        // }
        return config;
    },
    error => {
        return Promise.reject(error)
    }
)
export default service;