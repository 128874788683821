<template>
  <div class="footer">
      <p>华熙国际投资集团保留法律许可范围内所有解释权　版权所有©华熙国际投资集团有限公司</p>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.footer{
    width:100%;
    height:50px;
    background-color:#FFFFFF;
    display:flex;
    align-items:center;
    justify-content: center;
    position: fixed;
    bottom:0;
    left:0;
    p{
        color:#999999;
        font-size:14px;
    }
}
</style>