import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import store from '../store'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const routes = [{
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: '首页',
      footer: true
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import( /* webpackChunkName: "login" */ '../views/login/index.vue'),
    meta: {
      title: '登录'
    }
  },
  {
    path: '/enterprise',
    name: 'enterprise',
    component: () => import( /* webpackChunkName: "enterprise" */ '../views/login/enterprise.vue'),
    meta: {
      title: '绑定企业账号'
    }
  },
  {
    path: '/password',
    name: 'password',
    component: () => import( /* webpackChunkName: "password" */ '../views/login/password.vue'),
    meta: {
      title: '找回密码'
    }
  },
  {
    path: '/register',
    name: 'register',
    component: () => import( /* webpackChunkName: "register" */ '../views/register/index.vue'),
    meta: {
      title: '注册账号'
    }
  },
  {
    path: '/venue',
    name: 'venue',
    component: () => import( /* webpackChunkName: "venue" */ '../views/venue/index.vue'),
    meta: {
      title: '场馆列表',
      // requireAuth:true 
    }
  },
  {
    path: '/detail/:id',
    name: 'detail',
    component: () => import( /* webpackChunkName: "detail" */ '../views/venue/detail.vue'),
    meta: {
      title: '场馆详情'
    }
  },
  {
    path: '/reserve',
    name: 'reserve',
    component: () => import( /* webpackChunkName: "reserve" */ '../views/venue/reserve.vue'),
    meta: {
      title: '场馆预订',
      // requireAuth: true
    }
  },
  {
    path: '/schedule',
    name: 'schedule',
    component: () => import( /* webpackChunkName: "schedule" */ '../views/personal/schedule.vue'),
    meta: {
      title: '预订信息',
      personal: true,
      // requireAuth: true
    }
  },
  {
    path: '/essential',
    name: 'essential',
    component: () => import( /* webpackChunkName: "essential" */ '../views/personal/essential.vue'),
    meta: {
      title: '基本信息',
      personal: true
    }
  },
  {
    path: '/message',
    name: 'message',
    component: () => import( /* webpackChunkName: "message" */ '../views/personal/message.vue'),
    meta: {title: '消息', personal: true,equireAuth: true}
  },
  {
    path: '/security',
    name: 'security',
    component: () => import( /* webpackChunkName: "security" */ '../views/personal/security.vue'),
    meta: {
      title: '安全设置',
      personal: true
    }
  },
  {
    path: '/modify',
    name: 'modify',
    component: () => import( /* webpackChunkName: "modify" */ '../views/personal/modify.vue'),
    meta: {
      title: '修改手机号',
      personal: true
    }
  },
  {
    path: '/modifyPass',
    name: 'modifyPass',
    component: () => import( /* webpackChunkName: "modifyPass" */ '../views/personal/modifyPass.vue'),
    meta: {
      title: '修改密码',
      personal: true
    }
  },
  {
    path: '/authorization',
    name: 'authorization',
    component: () => import( /* webpackChunkName: "authorization" */ '../views/personal/authorization.vue'),
    meta: {
      title: '微信授权',
      personal: true
    }
  },
  {
    path: '/perDetail',
    name: 'perDetail',
    component: () => import( /* webpackChunkName: "perDetail" */ '../views/personal/perDetail.vue'),
    meta: {
      title: '详情',
      personal: true
    }
  },
  {
    path: '/sonalDetail',
    name: 'sonalDetail',
    component: () => import( /* webpackChunkName: "perDetail" */ '../views/personal/sonalDetail.vue'),
    meta: {
      title: '详情',
      personal: true
    }
  },
  {
    path: '/list',
    name: 'list',
    component: () => import( /* webpackChunkName: "list" */ '../views/list.vue'),
    meta: {
      title: '场馆列表'
    }
  },
  {
    path: '/ceshi',
    name: 'ceshi',
    component: () => import( /* webpackChunkName: "list" */ '../views/ceshi.vue'),
    meta: {
      title: 'ceshi'
    }
  },
]

// 页面刷新时，重新赋值token
// if (localStorage.getItem('Authorization ')) {
//   store.commit('set_token', localStorage.getItem('Authorization '))
// }

const router = new VueRouter({
  mode: 'history',
  routes
})

// 路由拦截 有些页面没登录看不到里边的内容
router.beforeEach((to, from, next) => {
  if (to.meta.requireAuth) { //判断该路由是否需要登录权限
    if (store.state.Authorization) { //通过vuex state 获取当前的token是否存在
      // console.log(router);
      next()
    } else {
      // 拦截未登录之前点击的那个路径,登录后就跳到那个路径
      // router.replace({
      //   name: "login",
      //   query: {
      //     redirect: router.currentRoute.fullPath
      //   }
      // })
      // console.log(to);
      next({
        path: '/login',
        query: {
          name: to.path
        } //将跳转的路由path作为参数.登录成功后跳转到该页
      })
      // next()

    }
  } else {

    next()
  }
})

export default router